.slick-slide>div{
  padding: 0 10px;
}

.swiper-button-prev, .swiper-button-next {
  color:white; 
  background-color: rgba(0,0,0,0.4);
  height: 40px;
  width: 40px;
  font-weight: 600;
  border-radius: 100%;
  text-align: center;
  margin:0 30px;
}

.swiper-button-prev:hover, .swiper-button-next:hover{
  background-color: rgba(0,0,0,.5);
} 


