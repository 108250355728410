
.headerBlur:before {
    content: "";
    position: absolute;
    width : 100%;
    height: 100%;
    background: inherit;
    z-index: -1;

    filter        : blur(10px);
    -moz-filter   : blur(10px);
    -webkit-filter: blur(10px);
    -o-filter     : blur(10px);

    transition        : all 2s linear;
    -moz-transition   : all 2s linear;
    -webkit-transition: all 2s linear;
    -o-transition     : all 2s linear;
}


::-webkit-scrollbar:horizontal{
    height: 0px;
    background-color: red;
  }
  ::-webkit-scrollbar-thumb:horizontal{
          background: #000;
          border-radius: 10px;
          
      }

      
.sliderWrapper {
    :global(.swiper-pagination-bullet) {
      background: lightgray;
      
      opacity: 1;
  }
  
  :global(.swiper-pagination-bullet-active) {
    background-color: white;
   
  }
  }
  
  .navHover::after{
    position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: #d14a61;
  height: 2px;
  }

  .navHover:hover::after{
    width: 100%;
  }

  .navHover,
  .navHover::after,
  .navHover::before {
  transition: all .5s;
}